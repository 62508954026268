<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">协议审批管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">协议审批列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ crumbsTitle }}</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <el-form ref="ruleForm" style="width: 100%" size="small" :disabled="disableState()" :rules="rule"
            :model="formData" label-width="140px">
            <div class="ovy-a">
              <div class="formTitle" style="width: 43.75rem">
                <div>
                  <h3><span>基本信息</span></h3>
                  <div class="form-box">
                    <el-form-item label="协议名称" prop="protocolName">
                      <el-input clearable placeholder="请输入协议名称" v-model="formData.protocolName"></el-input>
                    </el-form-item>
                    <el-form-item label="签订机构" prop="signCompId">
                      <el-select style="width: 100%" size="small" v-model="formData.signCompId" remote @change="companyChange"
                        :remote-method="getCompanyList" filterable clearable placeholder="请至少输入两个字搜索">
                        <el-option v-for="item in companyList" :key="item.compId" :label="item.compName"
                          :value="item.compId"></el-option>
                      </el-select>
                    </el-form-item>
                    <!--20230323-->
                    <el-form-item label="所属行政区划" prop="areaId">
                      <el-cascader clearable filterable v-model="formData.areaId" :options="areatreeList"
                        :props="propsarea" size="small"></el-cascader>
                    </el-form-item>
                    <!--20230323-->
                    <el-form-item label="协议类型" prop="protocolType">
                      <el-select clearable placeholder="请选择协议类型" style="width: 100%" v-model="formData.protocolType">
                        <el-option v-for="item in protocolTypeOptions" :key="item.value" :label="item.label"
                          :value="item.value">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="签订日期" prop="signDate">
                      <el-date-picker v-model="formData.signDate" type="date" value-format="yyyy-MM-dd"
                        placeholder="请选择签订日期">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item label="有效期" prop="termValidity">
                      <el-date-picker v-model="formData.termValidity" placeholder="请选择有效期" type="daterange"
                        value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
                      </el-date-picker>
                    </el-form-item>
                    <el-form-item prop="isSign" class="form-item" label="是否申请电子章">
                      <el-radio-group v-model="formData.isSign" :disabled="routeStatus!='1'&&!formData.isApproval">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <template v-if="formData.isSign">
                      <el-form-item prop="fileArr2" class="form-item" label="上传签章协议">
                        <div style="display: flex; flex-direction: column">
                          <span style="color: #f46173" v-for="(item, index) in formData.fileArr2" :key="index">
                            {{ item.fileName }}
                            <a @click="previewFile(item)" style="color: #5bb5ff">查看</a>
                            <a @click="reomveExl2(index)" style="color: #5bb5ff; margin-left: 15px"
                              v-show="routeStatus==='1'||formData.isApproval">删除</a>
                          </span>
                        </div>
                        <el-upload ref="fileArr2" class="upload-demo upload-btns" :action="actionUrl"
                          :on-error="handleError" :on-success="handleSuccess" :on-change="uploadChange2"
                          :show-file-list="false" :auto-upload="false" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-button style="height:40px;" size="small" class="bgc-bv"
                            :disabled="routeStatus!='1'&&!formData.isApproval">选择文件</el-button>
                        </el-upload>
                      </el-form-item>
                      <el-form-item prop="modelType" class="form-item" label="选择章">
                        <el-radio-group v-model="formData.modelType" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-radio label="1">北京晟融公章</el-radio>
                          <el-radio label="2">北京晟融合同章</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="keywordName" class="form-item" label="关键字">
                        <el-radio-group v-model="formData.keywordName" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-radio label="1">北京晟融</el-radio>
                          <el-radio label="2">公章</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="keywordPosition" class="form-item" label="盖章位置">
                        <el-radio-group v-model="formData.keywordPosition" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-radio label="1">第一页</el-radio>
                          <el-radio label="3">每一页</el-radio>
                          <el-radio label="2">最后一页</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="perforationPosition" class="form-item" label="是否加盖骑缝">
                        <el-radio-group v-model="formData.perforationPosition" @click.native="onRadioChange($event)" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-radio label="1">纵向</el-radio>
                          <el-radio label="2">横向</el-radio>
                        </el-radio-group>
                      </el-form-item>
                      <el-form-item prop="perforationPosition" class="form-item" label="骑缝位置" v-if="formData.perforationPosition">
                        <el-radio-group v-model="formData.pageSealPosition" :disabled="routeStatus!='1'&&!formData.isApproval">
                          <el-radio label="1">上</el-radio>
                          <el-radio label="2">中</el-radio>
                          <el-radio label="3">下</el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </template>
                    <el-form-item label="是否需要审批" prop="isApproval">
                      <el-radio-group v-model="formData.isApproval" :disabled="routeStatus!='1'" @change="isApprovalChange">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否财务复核" prop="isFinancialReview" v-if="formData.isApproval">
                      <el-radio-group v-model="formData.isFinancialReview">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="是否需要第二级审批" prop="isApprove" v-if="formData.isApproval">
                      <el-radio-group v-model="formData.isApprove" @change="isApproveChange">
                        <el-radio :label="true">是</el-radio>
                        <el-radio :label="false">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="第一审批人" prop="reviewerId" v-if="formData.isApproval">
                      <el-select style="width: 100%" v-model="formData.reviewerId" placeholder="请选择第一审批人" clearable>
                        <el-option v-for="item in approver1" :key="item.userId" :label="item.fullname"
                          :value="item.userId">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="第二审批人" prop="approverId" v-if="formData.isApproval&&formData.isApprove">
                      <el-select style="width: 100%" v-model="formData.approverId" placeholder="请选择第二审批人" clearable>
                        <el-option v-for="item in approver2" :key="item.userId" :label="item.fullname"
                          :value="item.userId">
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="合同对接业务员" prop="salesman">
                      <!-- <el-input v-model="formData.salesman" clearable></el-input> -->
                      <el-select style="width: 100%" size="small" v-model="formData.salesman" clearable filterable
                        placeholder="请选择对接业务员">
                        <el-option v-for="item in salesmanList" :key="item.saleId" :label="item.saleName"
                          :value="item.saleName">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="formTitle" style="width: 43.75rem">
                <div>
                  <h3><span>结算信息</span></h3>
                  <div class="form-box">
                    <el-form-item label="结算标准" prop="settleStandardType">
                      <el-radio-group v-model="formData.settleStandardType" @change="settleStandardTypeChange">
                        <el-radio label="10">技术/课程费分开</el-radio>
                        <el-radio label="20">技术/课程费合计</el-radio>
                        <el-radio label="30">固定费用</el-radio>
                        <el-radio label="40">其他</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="
                      formData.settleStandardType !== '30' &&
                      formData.settleStandardType !== '40' &&
                      formData.settleStandardType !== ''
                    ">
                      <el-radio-group @change="settleStandardTypeChange" v-model="formData.charge">
                        <el-radio label="10">按比例收费</el-radio>
                        <el-radio label="20">按人数收费</el-radio>
                        <el-radio label="30">按课时</el-radio>
                        <el-radio label="40" v-if="formData.settleStandardType == '20'">按次结算</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <!-- 分开 比例收费 -->
                    <div class="gezhongshoufei" v-if="
                      formData.settleStandardType === '10' &&
                      formData.charge === '10'
                    ">
                      <div style="display: flex; flex-direction: column">
                        <el-form-item prop="technologyRatio" key="technologyRatio" v-if="
                          formData.settleStandardType === '10' &&
                          formData.charge === '10'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 4rem">技术比例:</span>
                              <el-input v-model="formData.technologyRatio" @input="filter('technologyRatio')"
                                size="small">
                                <template slot="append">%</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item prop="coursesRatio" key="coursesRatio" v-if="
                          formData.settleStandardType == '10' &&
                          formData.charge == '10'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 4rem">课程比例:</span>
                              <el-input style="flex-grow: 1" @input="filter('coursesRatio')"
                                v-model="formData.coursesRatio" size="small">
                                <template slot="append">%</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <p style="margin-left: 11rem">
                        计算公式:技术费用=补贴标准*技术比例*人数
                        <span>课程费用=补贴标准*课程比例*人数</span>
                      </p>
                    </div>

                    <!-- 合计 比例收费 -->
                    <el-form-item prop="ratio" key="ratio" v-if="
                      formData.settleStandardType == '20' &&
                      formData.charge == '10'
                    ">
                      <div style="display: flex">
                        <div style="display: flex; align-items: center">
                          <span style="min-width: 4rem">比例:</span>
                          <el-input v-model="formData.ratio" @input="filter('ratio')" size="small">
                            <template slot="append">%</template>
                          </el-input>
                        </div>
                      </div>
                      <p>计算公式:平台服务费=补贴标准*比例*人数</p>
                    </el-form-item>

                    <!-- 分开 人数收费 -->
                    <div class="gezhongshoufei" v-if="
                      formData.settleStandardType == '10' &&
                      formData.charge == '20'
                    ">
                      <div style="display: flex; flex-direction: column">
                        <el-form-item prop="technologyCharge" key="technologyCharge" v-if="
                          formData.settleStandardType == '10' &&
                          formData.charge == '20'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 6rem">技术每人收费:</span>
                              <el-input v-model="formData.technologyCharge" @input="filter('technologyCharge')"
                                size="small">
                                <template slot="append">元</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item prop="coursesCharge" key="coursesCharge" v-if="
                          formData.settleStandardType == '10' &&
                          formData.charge == '20'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 6rem">课程每人收费:</span>
                              <el-input v-model="formData.coursesCharge" @input="filter('coursesCharge')" size="small">
                                <template slot="append">元</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <p style="margin-left: 11rem">
                        计算公式:技术费用=技术每人收费*人数
                        <span>课程费用=课程每人收费*人数</span>
                      </p>
                    </div>

                    <!-- 合计 人数收费 -->
                    <el-form-item prop="chargePerson" key="chargePerson" v-if="
                      formData.settleStandardType == '20' &&
                      formData.charge == '20'
                    ">
                      <div style="display: flex">
                        <div style="display: flex; align-items: center">
                          <span style="min-width: 4rem">每人收费:</span>
                          <el-input v-model="formData.chargePerson" @input="filter('chargePerson')" size="small">
                            <template slot="append">元</template>
                          </el-input>
                        </div>
                      </div>
                      <p>计算公式:平台服务费=每人收费*人数</p>
                    </el-form-item>

                    <!-- 分开 课时收费 -->
                    <div class="gezhongshoufei" v-if="
                      formData.settleStandardType == '10' &&
                      formData.charge == '30'
                    ">
                      <div style="display: flex; flex-direction: column">
                        <el-form-item prop="technologyChargePerson" key="technologyChargePerson" v-if="
                          formData.settleStandardType == '10' &&
                          formData.charge == '30'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 8rem">技术每人/课时收费:</span>
                              <el-input @input="filter('technologyChargePerson')"
                                v-model="formData.technologyChargePerson" size="small">
                                <template slot="append">元</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                        <el-form-item prop="coursesChargePerson" key="coursesChargePerson" v-if="
                          formData.settleStandardType == '10' &&
                          formData.charge == '30'
                        ">
                          <div style="display: flex">
                            <div style="display: flex; align-items: center">
                              <span style="min-width: 8rem">课程每人/课时收费:</span>
                              <el-input v-model="formData.coursesChargePerson" @input="filter('coursesChargePerson')"
                                size="small">
                                <template slot="append">元</template>
                              </el-input>
                            </div>
                          </div>
                        </el-form-item>
                      </div>
                      <p style="margin-left: 11rem">
                        计算公式:技术费用=技术每人/课时收费*人数*课时
                        <span>课程费用=课程每人/课时*人数*课时</span>
                      </p>
                    </div>

                    <!-- 合计 课时收费 -->
                    <el-form-item prop="chargeHourPerson" key="chargeHourPerson" v-if="
                      formData.settleStandardType == '20' &&
                      formData.charge == '30'
                    ">
                      <div style="display: flex">
                        <div style="display: flex; align-items: center">
                          <span style="min-width: 8rem">每人/课时收费:</span>
                          <el-input v-model="formData.chargeHourPerson" @input="filter('chargeHourPerson')" size="small">
                            <template slot="append">元</template>
                          </el-input>
                        </div>
                      </div>
                      <p>计算公式:平台服务费=每人/课时收费*人数*课时</p>
                    </el-form-item>
                    <!-- 合计 -- 按次数收费 -->
                    <el-form-item class="form-item" v-if="
                      formData.settleStandardType == '20' &&
                      formData.charge == '40'
                    ">
                      <div class="df card-border form-box" v-for="(item, index) in SettlementByTimeList" :key="index"
                        style="padding: 0">
                        <el-form :model="item" ref="cardform" label-width="7rem" class="form form-bg"
                          style="margin-bottom: 20px; padding: 0">
                          <el-form-item :label="'第' + (Number(index) + 1) + '次'" prop="SettlementByTime" :rules="[
                            {
                              required: true,
                              trigger: 'blur',
                              message:
                                '请输入第' +
                                (Number(index) + 1) +
                                '次结算金额',
                            },
                          ]">
                            <el-input placeholder="" v-model="item.SettlementByTime" size="small" :disabled="seeTH">
                              <template slot="append">元/人</template>
                            </el-input>
                          </el-form-item>
                        </el-form>
                        <div class="zsBtn" style="padding-left: 15px">
                          <el-button v-if="SettlementByTimeList.length > 1" @click="delCertificate(index)" type="text"
                            :disabled="item.disabled">删除</el-button>
                          <el-button v-if="
                            index == SettlementByTimeList.length - 1 &&
                            index != 9
                          " @click="certificate" type="text">添加</el-button>
                        </div>
                      </div>
                      <p>计算公式:平台服务费=人数*费用</p>
                    </el-form-item>
                    <!-- 固定费用 -->
                    <el-form-item prop="fixedCost" key="fixedCost" v-if="formData.settleStandardType === '30'">
                      <div style="display: flex">
                        <div style="display: flex; align-items: center">
                          <span style="min-width: 4rem">固定费用:</span>
                          <el-input v-model="formData.fixedCost" @input="filter('fixedCost')" size="small">
                            <template slot="append">元/年</template>
                          </el-input>
                        </div>
                      </div>
                    </el-form-item>

                    <el-form-item label="报价金额" prop="quoteAmount">
                      <el-input v-model="formData.quoteAmount" @input="filter('quoteAmount')" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="收费标准" prop="chargesSettlement">
                      <el-input show-word-limit maxlength="1000" type="textarea" resize="none" rows="8"
                        v-model="formData.chargesSettlement" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="备注">
                      <el-input show-word-limit maxlength="200" type="textarea" resize="none" rows="3"
                        v-model="formData.remark" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="上传附件">
                      <!-- action 上传地址，这里不填写 -->
                      <!-- sccept 在HTML层面做一下类型限制 -->
                      <!-- show-file-list 是否显示已上传的文件列表，这里不显示 -->
                      <!-- auto-upload 是否自动上传，这里不自动上传 -->
                      <!-- http-request 自定义上传行为，这里给个空函数 -->
                      <!-- multiple 是否可以一次性上传多个，这里允许 -->
                      <!-- drag 开启拖拽上传 -->
                      <!-- on-change 文件改变时触发-->
                      <el-upload class="upload-demo upload-btn upload-btndrag" action accept="application/pdf"
                        :show-file-list="false" :auto-upload="false" :http-request="
                          () => {
                            return;
                          }
                        " multiple drag :on-change="uploadChange">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          将文件拖到此处，或<em>点击上传</em>
                        </div>
                      </el-upload>
                      <div v-if="uploadfileList.length > 0" style="margin-top: 60px; margin-left: 10px">
                        当前选择文件：
                        <div style="
                                    display: flex;
                                    flex-direction: column;
                                    margin-top: 10px;
                                  ">
                          <span style="color: #409eff" v-for="(item, index) in uploadfileList" :key="index">
                            {{ item.fileName }}
                            <a style="color: #409eff; margin: 0 15px" @click="previewFile(item)">预览</a>
                            <a style="color: red; margin: 0 15px" v-if="!review && !see && !afterReview"
                              @click="reomveFile(index)">删除</a>
                          </span>
                        </div>
                      </div>
                      <div v-else>
                        <p style="margin-top: 60px; margin-left: 10px">
                          未选择文件
                        </p>
                      </div>
                    </el-form-item>

                    <!-- 财务复核时显示并编辑  复合后查看时显示不能编辑  -->
                    <el-form v-if="review || see || afterReview" :disabled="see" :model="reviewFormData" ref="reviewForm"
                      :rules="reviewRule" label-width="100px">
                      <el-form-item label="报备日期" prop="filingDate">
                        <el-date-picker size="small" v-model="reviewFormData.filingDate" type="date"
                          value-format="yyyy-MM-dd" placeholder="请选择报备日期">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label="财务备注">
                        <el-input show-word-limit maxlength="200" type="textarea" resize="none" rows="3"
                          v-model="reviewFormData.financialRemark" clearable></el-input>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </div>
              <!-- 财务复核 -->
              <div class="btn-box" v-if="review">
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="$router.back()">取 消</el-button>
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="toReview(true)">复核通过</el-button>
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="toReview(false)">复核不通过</el-button>
              </div>
              <!-- 查看 -->
              <div class="btn-box" v-else-if="see">
                <el-button type="promary" class="bgc-bv" :disabled="false" @click="$router.back()">返 回</el-button>
              </div>
              <!-- 复合后编辑 -->
              <div class="btn-box" v-else-if="afterReview">
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="$router.back()">取 消</el-button>
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="save">保 存</el-button>
              </div>
              <div class="btn-box" v-else>
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="$router.back()">取 消</el-button>
                <el-button type="primary" class="bgc-bv" :disabled="false" @click="submit">确 定</el-button>
              </div>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <el-dialog title="预览" :visible.sync="previewDialog" width="50%" top="2%" center>
      <div id="pdf-content" style="height: 43rem" v-if="previewFileType === 'pdf' || previewFileType === 'PDF'"></div>
      <div v-else>
        <img :src="previewImgSrc" alt="" style="width: 100%; height: 100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import pdf from "pdfobject";
export default {
  name: "agreementAdd",
  created() {
    this.judgeRoute();
    // 处理协议类型字典数据
    this.dataDictionary();
    // 获取审批人数据
    this.getUserInfo();
    // 获取业务员数据
    this.getSalesmanList();
    // 获取区划
    this.getareatree();
    this.SettlementByTimeList.push({ ...this.obj });
  },
  data() {
    // 正则 检测是否是合法百分比
    const validate1 = (rule, value, callback) => {
      if (!value) return callback();
      const reg = /^(100$|[1-9]?\d$|[1-9]?\d\.\d+$)/;
      if (!reg.test(value)) return callback("请输入合法百分比");
      callback();
    };
    // 正则 检测整数及小数后两位
    const validate2 = (rule, value, callback) => {
      if (!value) return callback();
      const reg = /^[0-9]+(\.[0-9]{0,2})?$/;
      if (!reg.test(value)) return callback("请输入合法数值");
      callback();
    };
    return {
      // 定义表单规则
      rule: {
        protocolName: [
          { required: true, message: "请输入协议名称", trigger: "blur" },
        ],
        areaId: [
          { required: true, message: "请选择所属行政区划", trigger: "change" },
        ],
        protocolType: [
          { required: true, message: "请选择协议类型", trigger: "change" },
        ],
        signCompId: [
          { required: true, message: "请选择签订机构", trigger: "change" },
        ],
        signDate: [
          { required: true, message: "请选择签订日期", trigger: "blur" },
        ],
        termValidity: [
          // {required:true,message:"请选择有效期",trigger:"blur"}
        ],
        settleStandardType: [
          // {required:true,message:"请选择结算标准",trigger:"blur"}
        ],
        // 技术比例
        technologyRatio: [
          { required: true, message: "请输入技术比例", trigger: "blur" },
          { validator: validate1, trigger: "blur" },
        ],
        // 课程比例
        coursesRatio: [
          { required: true, message: "请输入课程比例", trigger: "blur" },
          { validator: validate1, trigger: "blur" },
        ],
        // 合计比例
        ratio: [
          { required: true, message: "请输入比例", trigger: "blur" },
          { validator: validate1, trigger: "blur" },
        ],
        // 技术每人收费
        technologyCharge: [
          { required: true, message: "请输入技术每人收费", trigger: "blur" },
          { validator: validate2, trigger: "blur" },
        ],
        // 课程每人收费
        coursesCharge: [
          { required: true, message: "请输入课程每人收费", trigger: "blur" },
          { validator: validate2, trigger: "blur" },
        ],
        // 合计每人收费
        chargePerson: [
          { required: true, message: "请输入每人收费", trigger: "blur" },
          { validator: validate2, trigger: "blur" },
        ],
        // 分开 技术每人/课时收费
        technologyChargePerson: [
          {
            required: true,
            message: "请输入 技术每人/课时收费",
            trigger: "blur",
          },
          { validator: validate2, trigger: "blur" },
        ],
        // 分开 课程每人/课时收费
        coursesChargePerson: [
          {
            required: true,
            message: "请输入 课程每人/课时收费",
            trigger: "blur",
          },
          { validator: validate2, trigger: "blur" },
        ],
        // 合计课时收费
        chargeHourPerson: [
          { required: true, message: "请输入 每人/课时收费", trigger: "blur" },
          { validator: validate2, trigger: "blur" },
        ],
        // 固定费用
        fixedCost: [
          { required: true, message: "请输入固定费用", trigger: "blur" },
          { validator: validate2, trigger: "blur" },
        ],
        quoteAmount: [
          // {required:true,message:"请输入报价金额",trigger:"blur"}
        ],
        salesman: [
          { required: true, message: "请选择对接务业务员", trigger: "change" },
        ],
        chargesSettlement: [
          // {required:true,message:"请输入收费标准",trigger:"blur"}
        ],
        reviewerId: [
          { required: true, message: "请选择第一审批人", trigger: "change" },
        ],
        approverId: [
          { required: true, message: "请选择第二审批人", trigger: "change" },
        ],

        isSign: [
          {
            required: true,
            message: "请选择是否申请电子章",
            trigger: "change",
          },
        ],
        fileArr2: [
          {
            required: true,
            message: "请上传需要盖章的协议",
            trigger: "change",
          },
        ],
        modelType: [
          {
            required: true,
            message: "请选择选择章",
            trigger: "change",
          },
        ],
        keywordName: [
          {
            required: true,
            message: "请选择关键字",
            trigger: "change",
          },
        ],
        keywordPosition: [
          {
            required: true,
            message: "请选择盖章位置",
            trigger: "change",
          },
        ],
      },
      reviewRule: {
        filingDate: [
          { required: true, message: "请选择报备日期", trigger: "change" },
        ],
      },
      // 附件上传列表
      uploadfileList: [],
      // 预览对话框显示隐藏
      previewDialog: false,
      // 预览文件的类型
      previewFileType: "",
      // 图片预览的地址
      previewImgSrc: "",
      // 协议类型字典数据
      protocolTypeOptions: [],
      // 表单数据
      formData: {
        protocolName: "", //协议名称
        areaId: '', // 行政区划
        protocolType: "", //协议类型,
        signCompId: "", // 签订机构id
        signDate: "", //签订日期
        termValidity: [], //有效期
        isFinancialReview: false, //是否财务复核
        isApproval: true, //是否需要审批
        isApprove: true, //是否需要第二级审批
        settleStandardType: "", //结算标准
        reviewerId: "", //第一审批人
        approverId: "", // 第二审批人
        salesman: "", // 对接业务员
        quoteAmount: "", // 报价金额
        remark: "", // 备注
        fileList: [], // 附件
        chargesSettlement: "", //收费标准
        confirmUserId: "", // 财务复核人
        charge: "", // 结算二级标准
        technologyRatio: "", // 技术比例
        coursesRatio: "", // 课程比例
        ratio: "", // 合计比例收费
        technologyCharge: "", // 技术每人收费
        coursesCharge: "", // 课程每人收费
        chargePerson: "", // 合计人数收费 每人收费
        technologyChargePerson: "", // 分开课时收费 技术每人课时收费
        coursesChargePerson: "", // 分开课时收费 课程每人收费
        chargeHourPerson: "", // 合计课时收费 每人课时收费
        fixedCost: "", // 固定费用

        isSign: false,// 是否申请电子章
        fileArr2: [], // 上传盖章的协议
        modelType: '1',// 选择章:1北京晟融公章 2北京晟融合同章
        keywordName: '1',// 关键字:1北京晟融 2公章
        keywordPosition: '1',// 盖章位置:1第一页 2最后一页 3每页一个
        perforationPosition: '',// 骑缝章方向:1纵向 2横向
        pageSealPosition: '2',// 骑缝位置:1上 2中 3下
      },
      // 财务复核的数据
      reviewFormData: {
        filingDate: "", // 报备日期
        financialRemark: "", // 财务备注
        isPassed: false, // 是否通过
        protocolId: "", // 协议ID
        remark: "", // 驳回原因
      },
      // 第一审批人数据
      approver1: [],
      // 第二审批人数据
      approver2: [],
      // 签订机构数据
      companyList: [],
      // 对接业务员数据
      salesmanList: [],
      // 面包屑显示名称
      crumbsTitle: "",
      // 协议id 有id说明是编辑
      protocolId: "",
      // 复核 true代表复核
      review: false,
      // 是否是查看协议
      see: false,
      // 是否为财务复核以后的协议
      afterReview: false,
      // 按次结算数据
      SettlementByTimeList: [],
      obj: {
        SettlementByTime: "",
      },
      // 区划数据
      areatreeList: [],
      // 区划字段控制
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      routeStatus:'',//路由状态
    };
  },
  watch: {

  },
  methods: {
    // 是否加盖骑缝 - 单选的时候  可以在取消
    onRadioChange(e) {
      if (e.target.tagName === "INPUT") {
        if (this.formData.perforationPosition === "") {
          this.formData.perforationPosition = "1";
        } else {
          this.formData.perforationPosition = "";
        }
      }
    },
    // 获取区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 上传 - 盖章的协议
    uploadChange2(file) {
      this.$refs.fileArr2.clearFiles(); // 清空上传组价的数据
      this.formData.fileArr2 = []; // 每次上传清空，因为只能传一个
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";

      if (!isXLSX && !isxlsx) {
        this.$message.error("只能上传后缀是.pdf文件");
        return;
      }
      if (size > 5) {
        this.$message.error("文件大小不能超过5M");
        return;
      }
      let fileName = file.name;
      let formData = new FormData();
      formData.append("folder ", "QUESTION");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.formData.fileArr2.push({
            fileName: fileName,
            fileKey: result.data.fileKey,
            fileURL: result.data.fileURL,
          });
          this.$forceUpdate();  
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "保存失败",
            });
          }, 300);
        });
    },
    // 删除 - 盖章的协议
    reomveExl2(index) {
      this.formData.fileArr2.splice(index, 1);
      this.$forceUpdate();
    },
    // 新增 -- 按次结算
    certificate() {
      this.SettlementByTimeList.push({ ...this.obj });
    },
    delCertificate(ind) {
      // if (this.SettlementByTimeList[ind].teacherId) {
      //   this.delIds.push({
      //     teacherQualificationId: this.addlist[ind].teacherQualificationId,
      //   });
      // }
      this.SettlementByTimeList.splice(ind, 1);
    },
    //按次结算校验
    check1() {
      const cardform = this.$refs["cardform"];
      let stu = true;
      for (let i = 0; i < cardform.length; i++) {
        cardform[i].validate((valid1) => {
          if (valid1 == false) {
            stu = false;
          }
        });
      }
      return stu;
    },
    async companyChange(val){
      if(val){
        let obj = await this.getCompanyInfo(val)
        obj.areaId && (this.formData.areaId = obj.areaId)
        obj.saleName && (this.formData.salesman = obj.saleName)
      }
    },
    // 设置表单项的可用状态
    disableState() {
      const { protocolId, review, see, afterReview } = this;
      // 没有protocolId说明是新增，所以表单都可用
      if (!protocolId) return false;
      // 如果有protocolId 则判断一下几种状态
      if (protocolId) {
        // 如果有review代表复核，只有财务复核表单可用
        if (review) return true;
        // 如果有see代表仅查看，所有表单不可用
        if (see) return true;
        // 是否为复核后编辑
        if (afterReview) return true;
        // 如果没有review也没有see则为编辑状态，此时表单可用，除财务复核表单
        return false;
      }
    },
    // 上传的附件状态改变时
    async uploadChange(file) {
      // 定义上传类型
      const fileType = ["pdf"];
      // 拿到文件大小
      const size = file.size / 1024 / 1024;
      // 拿到扩展名
      const extension = file.name.slice(file.name.lastIndexOf(".") + 1);
      // 判断文件上传类型是否正确
      if (!fileType.includes(extension.toLowerCase()))
        return this.$message.error("只能上传pdf格式附件");
      // 判断文件大小是否符合要求（20MB）
      if (size > 20) return this.$message.error("附件大小不能超过20MB");
      // 定义需要给服务器传递的数据
      const formData = new FormData();
      formData.append("folder", "USER");
      formData.append("file", file.raw);
      formData.append("fileType", extension);
      try {
        // 拿到服务器返回的fileKey和fileUrl做进一步处理
        const result = await this.$Postformat("/sys/upload", formData);
        this.uploadfileList.push({
          fileName: file.name,
          fileKey: result.data.fileKey,
          fileType: extension,
          fileUrl: result.data.fileURL, // 这个暂时不传
        });
      } catch (e) {
        return "文件上传失败";
      }
    },
    // 获取审批人数据
    getUserInfo() {
      // 第一审批人
      this.$post("/biz/audit/auditUser/getUserInfo", {
        userRole: "30",
      })
        .then((res) => {
          this.approver1 = res.data || [];
        })
        .catch((e) => console.log(e));
      // 第二审批人
      this.$post("/biz/audit/auditUser/getUserInfo", {
        userRole: "40",
      })
        .then((res) => {
          this.approver2 = res.data || [];
        })
        .catch((e) => console.log(e));
      // 财务复核人
      this.$post("/biz/audit/auditUser/getUserInfo", {
        userRole: "20",
      })
        .then((res) => {
          this.formData.confirmUserId = res?.data[0]?.userId || "";
        })
        .catch((e) => console.log(e));
    },
    // 获取签订机构数据
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", {
          compName: query.trim(),
        })
          .then((res) => {
            this.companyList = res.data || [];
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 获取签订机构详情
    getCompanyInfo(compId) {
        return this.$post("/sys/company/info", {
          compId
        })
        .then((res) => {
          return res.data
        })
        .catch(() => {
          return;
        });
    },
    
    // 获取业务员数据
    getSalesmanList() {
      this.$post("/biz/new/bill/contract/findSaleManList", {}).then((res) => {
        this.salesmanList = res.data || [];
      });
    },
    // 预览附件
    previewFile(file) {
      this.previewFileType = file.fileName.slice(
        file.fileName.lastIndexOf(".") + 1
      );
      this.previewDialog = true;
      if (this.previewFileType === "pdf" || this.previewFileType === "PDF") {
        this.$nextTick(() => {
          pdf.embed(file.fileUrl, "#pdf-content");
        });
      } else {
        this.previewImgSrc = file.fileUrl;
      }
    },
    // 删除附件
    reomveFile(index) {
      this.uploadfileList.splice(index, 1);
    },
    // 过滤非法字符
    filter(key) {
      const { formData } = this;
      const value = formData[key].replace(/[^\d.]/, "");
      formData[key] = value;
    },
    // 结算标准改变
    settleStandardTypeChange() {
      const { formData } = this;
      // const obj={
      //     technologyRatio:"", // 分开比例收费 技术比例
      //     coursesRatio:"", // 分开比例收费 课程比例
      //     ratio:"", // 合计 比例
      //     technologyCharge:"", // 分开人数收费 技术每人收费
      //     coursesCharge:"",// 分开人数收费 课程每人收费
      //     chargePerson:"",// 合计人数收费 每人收费
      //     technologyChargePerson:"",// 分开课时收费 技术每人/课时收费
      //     coursesChargePerson:"",// 分开课时收费 课程每人/课时收费
      //     chargeHourPerson:"",// 合计课时收费 每人/课时收费
      //     fixedCost:"" // 固定收费
      // }
      const arrKey = [
        "technologyRatio",
        "coursesRatio",
        "ratio",
        "technologyCharge",
        "coursesCharge",
        "chargePerson",
        "technologyChargePerson",
        "coursesChargePerson",
        "chargeHourPerson",
        "fixedCost",
      ];
      const arr = [];
      if (formData.settleStandardType === "10" && formData.charge === "10") {
        arr.push("technologyRatio", "coursesRatio");
      } else if (
        formData.settleStandardType === "20" &&
        formData.charge === "10"
      ) {
        arr.push("ratio");
      } else if (
        formData.settleStandardType === "10" &&
        formData.charge === "20"
      ) {
        arr.push("technologyCharge", "coursesCharge");
      } else if (
        formData.settleStandardType === "20" &&
        formData.charge === "20"
      ) {
        arr.push("chargePerson");
      } else if (
        formData.settleStandardType === "10" &&
        formData.charge === "30"
      ) {
        arr.push("technologyChargePerson", "coursesChargePerson");
      } else if (
        formData.settleStandardType === "20" &&
        formData.charge === "30"
      ) {
        arr.push("chargeHourPerson");
      } else if (formData.settleStandardType === "30") {
        arr.push("fixedCost");
      }
      // 重置表单项
      arrKey.forEach((key) => {
        if (!arr.includes(key)) formData[key] = "";
      });
    },
    // 提交
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const { formData, protocolId } = this;
          const arr = [
            "technologyRatio",
            "coursesRatio",
            "ratio",
            "technologyCharge",
            "coursesCharge",
            "chargePerson",
            "technologyChargePerson",
            "coursesChargePerson",
            "chargeHourPerson",
            "fixedCost",
            "charge", // 二级分类
          ];
          // 定义json数据
          const settleContent = {};
          // 定义请求参数
          const params = JSON.parse(JSON.stringify(formData));
          // 行政区划
          params.areaId = this.formData.areaId;
          // 是否申请电子章
          params.isSign = this.formData.isSign;
          if (this.formData.isSign) {
            params.fileKey = this.formData.fileArr2[0].fileKey;// 上传盖章的文件key
            params.fileName = this.formData.fileArr2[0].fileName; // 上传盖章的文件名
            params.modelType = this.formData.modelType;// 选择章
            params.keywordName = this.formData.keywordName;// 关键字
            params.keywordPosition = this.formData.keywordPosition;// 盖章位置
            params.perforationPosition = this.formData.perforationPosition;// 骑缝章方向
          }
          if (
            this.formData.settleStandardType == "20" &&
            this.formData.charge == "40"
          ) {
            if (this.check1()) {
              settleContent.SettlementByTimeList = this.SettlementByTimeList;
            }
          }
          // 处理有效期参数
          params.startDate = params.termValidity[0];
          params.endDate = params.termValidity[1];
          // 处理附件类型数据
          params.fileList = this.uploadfileList;
          // 处理json数据
          arr.forEach((item) => {
            if (params[item]) settleContent[item] = params[item];
          });
          params["settleContent"] = JSON.stringify(settleContent);
          // 删除无用数据
          arr.forEach((item) => {
            delete params[item];
          });
          delete params.termValidity;

          // 判断是新增还是编辑
          if (protocolId) {
            // 编辑
            params["protocolId"] = protocolId;
            this.$post("/biz/protocol/modify", params)
              .then(() => {
                this.$message.success("修改成功");
                this.$router.back();
              })
              .catch((e) => console.log(e));
          } else {
            // 新增
            this.$post("/biz/protocol/insert", params)
              .then((res) => {
                this.$message.success("添加成功");
                this.$router.back();
              })
              .catch((e) => console.log(e));
          }
        }
      });
    },
    // 复合后编辑
    save() {
      const { reviewFormData } = this;
      const condit = {
        filingDate: reviewFormData.filingDate,
        financialRemark: reviewFormData.financialRemark,
        protocolId: reviewFormData.protocolId,
      };
      this.$refs.reviewForm.validate((valid) => {
        if (!valid) return;
        this.$post("/biz/protocol/financial/modify", condit)
          .then(() => {
            this.$message.success("保存成功");
            this.$router.back();
          })
          .catch((e) => console.log(e));
      });
    },
    // 编辑回显数据
    async echo() {
      const { protocolId, formData, reviewFormData } = this;
      try {
        const res = await this.$post("/biz/protocol/getInfo", { protocolId });
        // 获取绑定机构数据
        this.getCompanyList(res.data.compName);
        // 处理回显信息
        Object.keys(formData).forEach((key) => {
          if (typeof res.data[key] == "boolean") {
            formData[key] = res.data[key];
          } else {
            if (res.data[key]) formData[key] = res.data[key];
          }
        });
        // 处理复合后的回显信息
        Object.keys(reviewFormData).forEach((key) => {
          if (res.data[key]) reviewFormData[key] = res.data[key];
        });
        reviewFormData.filingDate = reviewFormData.filingDate.replaceAll(
          "/",
          "-"
        );
        // 处理结算标准
        const settleContent = JSON.parse(res.data.settleContent);
        Object.keys(settleContent).forEach((key) => {
          formData[key] = settleContent[key];
        });

        this.SettlementByTimeList = JSON.parse(res.data.settleContent)
          .SettlementByTimeList || [{ ...this.obj }];

        // 处理有效期
        formData.termValidity = [
          res.data.startDate ? res.data.startDate.replaceAll("/", "-") : "",
          res.data.endDate ? res.data.endDate.replaceAll("/", "-") : "",
        ];
        formData.areaId = res.data.areaId;
        formData.isSign = res.data.isSign ? true : false;
        formData.fileArr2 = res.data.fileKey ? [{
          fileName: res.data.fileName,
          fileKey: res.data.fileKey,
          fileUrl: res.data.fileUrl,
        }] : [];
        formData.modelType = res.data.modelType || '1';
        formData.keywordName = res.data.keywordName || '1';
        formData.keywordPosition = res.data.keywordPosition || '1';
        formData.perforationPosition = res.data.perforationPosition || "";
        // 处理签订日期
        formData.signDate = formData.signDate.replaceAll("/", "-");
        // 处理附件
        formData.fileList = res.data.list;
        this.uploadfileList = res.data.list;
      } catch (e) {
        console.log(e);
      }
    },
    // 复核按钮
    toReview(isPassed) {
      const { reviewFormData } = this;
      reviewFormData.protocolId = this.protocolId;
      if (isPassed) {
        // 通过
        this.$refs.reviewForm.validate((valid) => {
          if (valid) {
            reviewFormData.isPassed = true;
            this.$post(
              "/biz/protocol/audit/financialReview",
              this.reviewFormData
            ).then((res) => {
              this.$message.success("操作成功");
              this.$router.back();
            });
          }
        });
      } else {
        // 不通过
        this.$refs.reviewForm.validate((valid) => {
          if (valid) {
            reviewFormData.isPassed = false;
            this.$prompt("驳回原因", "", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              inputPattern: /^.{2,50}$/,
              inputErrorMessage: "请输入驳回原因，最少2字符",
            }).then(({ value }) => {
              reviewFormData.remark = value;
              this.$post(
                "/biz/protocol/audit/financialReview",
                this.reviewFormData
              ).then((res) => {
                this.$message.success("操作成功");
                this.$router.back();
              });
            });
          }
        });
      }
    },
    // 获取数据字典（协议类型）
    dataDictionary() {
      const protocolType = this.$setDictionary("PROTOCOL_TYPE", "list");
      Object.keys(protocolType).forEach((key) => {
        this.protocolTypeOptions.push({
          label: protocolType[key],
          value: key,
        });
      });
    },
    // 是否需要审批
    isApprovalChange() {
      this.formData.isApprove = false
      this.formData.reviewerId = ''
      this.formData.approverId = ''
      this.formData.isFinancialReview = false
    },
    // 是否需要二次审批
    isApproveChange() {
      this.formData.approverId = ''
    },
    // 路由信息判断
    judgeRoute() {
      if (this.$route.query.review && this.$route.query.protocolId) {
        // 复核协议
        this.crumbsTitle = "复核协议";
        this.protocolId = this.$route.query.protocolId;
        this.review = this.$route.query.review;
        this.echo();
      } else if (this.$route.query.see && this.$route.query.protocolId) {
        // 查看协议
        this.crumbsTitle = "查看协议";
        this.protocolId = this.$route.query.protocolId;
        this.see = this.$route.query.see;
        this.echo();
      } else if (
        this.$route.query.protocolId &&
        this.$route.query.afterReview
      ) {
        // 财务复合后编辑
        this.crumbsTitle = "查看协议详情";
        this.protocolId = this.$route.query.protocolId;
        this.afterReview = this.$route.query.afterReview;
        this.echo();
      } else if (
        this.$route.query.protocolId &&
        !this.$route.query.review &&
        !this.$route.query.see &&
        !this.$route.query.afterReview
      ) {
        // 编辑协议
        this.crumbsTitle = "编辑协议";
        this.protocolId = this.$route.query.protocolId;
        this.echo();
      } else if (!this.$route.query.protocolId) {
        // 新增协议
        this.crumbsTitle = "新增协议";
        this.routeStatus = '1'
      }
    },
  },
};
</script>

<style lang="less" scoped>
.framePage {
  .formTitle {
    .el-icon-plus:before {
      content: "\e6d9";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .el-cascader--small {
      line-height: 40px;
    }

    h3 {
      display: flex;
      border-left: 3px solid rgb(92, 107, 232);
      margin-bottom: 30px;

      span {
        margin-left: 20px;
      }
    }
  }

  .framePage-body {
    .form-box {
      padding: 10px 20px;

      .el-form {
        padding: 20px;
      }
    }

    .btn-box {
      width: 43.75rem;
      display: flex;
      text-align: center;
      justify-content: space-evenly;
    }

    .gezhongshoufei {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  /deep/.noMargin .el-form-item__content {
    margin-left: 10px !important;
  }

  /deep/.el-upload {
    border: none !important;
  }
}

/deep/.upload-btns .el-upload {
  height: 40px !important;
  border: none !important;
}
</style>